import React, { useContext, useEffect, useState } from 'react';
import Control from '../sideMenus/Control';
import Stats, { Score } from '../sideMenus/Stats';
import styles from './Scene.module.scss';
import SpeechBubble from './SpeechBubble';

import { GameMode, PointerEventHandler } from '../../engine/game'
import { GameObjectModel } from 'engine/models/gameObject';
import { SceneModel } from '../../engine/scenes';
import { GameGlobalsContext } from 'contexts/GameGlobalsContext';
import gameService from 'services/gameService';
import { AreaNode } from 'components/settings/AreaNodeSettings/area_nodes';
import { initSaveStateHandler, SaveStateHandler } from 'engine/saveStateHandler';
import SkinSettings, { Skin } from 'components/settings/SkinChoiceSettings/SkinSettings';
import AreaNodesSettings, { SettingsProps } from 'components/settings/AreaNodeSettings/AreaNodeSettings';
import skinQuizPng from './skin-quiz-2.png'
import skinGladPng from './skin-gladiator-3.png'
import skinAdvPng from './skin-adventure-2.png'

export function GameObjectBase({ onEvent, gameObject, imageUrl }: { onEvent: PointerEventHandler, gameObject: GameObjectModel, imageUrl: string }) {
  const style = {
    top: `${gameObject.yPos}%`,
    left: `${gameObject.xPos}%`,
    opacity: gameObject.opacity,
    width: `${gameObject.width}%`,
    height: `${gameObject.height}%`,
    backgroundImage: gameObject.avatar == null ? undefined : `url('${imageUrl}')`,
  };

  const onClick = gameObject.clickable ? () => onEvent({ type: 'click', target: gameObject, position: null }) : undefined;
  return (
    <div id={gameObject.id} key={gameObject.id} className={`${styles.avatar} ${gameObject.clickable ? styles.hasInteraction : ''}`} style={style} onClick={onClick}>
      {gameObject.message && <SpeechBubble {...gameObject.message} />}
    </div>
  );
}

function GameObject({ onEvent, gameObject }: { onEvent: PointerEventHandler, gameObject: GameObjectModel }) {
  const { adventure, theme } = useContext(GameGlobalsContext);
  return <GameObjectBase gameObject={gameObject} onEvent={onEvent} imageUrl={`./assets/${adventure}-${theme}/${gameObject.avatar}.png`} />
}

function DemoGameObject({ gameObject, adventure, theme }: { gameObject: GameObjectModel, adventure: string, theme: string }) {
  const style = {
    top: `${gameObject.yPos}%`,
    left: `${gameObject.xPos}%`,
    opacity: gameObject.opacity,
    width: `${gameObject.width}%`,
    height: `${gameObject.height}%`,
    backgroundImage: gameObject.avatar == null ? undefined : `url('./assets/${adventure}-${theme}/${gameObject.avatar}.png')`
  };

  return (
    <div id={gameObject.id} key={gameObject.id} className={`${styles.avatar}`} style={style}>
      {gameObject.message && <SpeechBubble {...gameObject.message} />}
    </div>
  );
}

function sortOnZ(lh: GameObjectModel, rh: GameObjectModel) {
  if (lh.zPos === rh.zPos) return 0;
  return lh.zPos > rh.zPos ? 1 : -1;
}

const hardCodedSkins: Skin[] = [
  { name: 'frågesport', adventureId: "bare", theme: 'default', image: skinQuizPng, description: 'Utmana dig själv och dina vänner', isHorizontal: false },
  { name: 'gladiator', adventureId: "battleground", theme: 'fantasy', image: skinGladPng, description: 'Hur många motståndare kan du besegra?', isHorizontal: true },
  // { name: 'det stora äventyret', adventureId: 'eternal', theme: 'fantasy', image: skinAdvPng, description: 'Kan du lösa mysteriet?', isHorizontal: true }
];

const prepareSettings = (onClose: () => void, saveChanges: (skin: Skin, nodes: AreaNode[]) => void, saveStateHandler: SaveStateHandler) => {
  return {
    skins: hardCodedSkins,
    nodes: [],
    onClose: onClose,
    saveChanges: saveChanges,
    saveStateHandler: saveStateHandler
  }
}

const handleSkinSettingsSave = async (skin: Skin, saveStateHandler: SaveStateHandler) => {
  const gameDef = await gameService.getGameDefinition(skin.adventureId, skin.theme);
  saveStateHandler.saveScene({ adventureId: gameDef.adventure, sceneId: gameDef.scenes[0].id, theme: gameDef.theme }, false).then(result =>
    window.location.reload()
  )
}

const setupSkinSettingsProps = (
  setOpenSkinSettingsWindow: (newValue: boolean) => void,
  handleSkinSettingsSave: (skin: Skin, saveStateHandler: SaveStateHandler) => void,
  setOpenNodeSettingsWindow: (newValue: boolean) => void,
  saveStateHandler: SaveStateHandler,
) => {
  return {
    skins: hardCodedSkins,
    nodes: [],
    onClose: () => setOpenSkinSettingsWindow(false),
    saveChanges: (skin: Skin, nodes: AreaNode[]) => {
      handleSkinSettingsSave(skin, saveStateHandler);
      setOpenNodeSettingsWindow(false)
    },
    saveStateHandler: saveStateHandler
  }
}

const handleNodeSettingsSave = async (nodes: AreaNode[]) => {
  const params = new URLSearchParams(window.location.search);
  const paramValue = params.get("node") ?? undefined;
  if (nodes[0]?.id === paramValue) {
    return
  }
  window.sessionStorage.setItem('reload', 'true');
  window.history.pushState(null, nodes.length > 0 ? nodes[0].headline : 'nodes', nodes.length > 0 ? `/?node=${nodes[0].id}` : '/')
  window.location.reload()
}

const setupNodeSettignsProps = (
  nodes: AreaNode[],
  setOpenNodeSettingsWindow: (newValue: boolean) => void,
  handleNodeSettingsSave: (nodes: AreaNode[]) => void,
  saveStateHandler: SaveStateHandler,
) => {
  return {
    skins: [],
    nodes: nodes,
    onClose: () => setOpenNodeSettingsWindow(false),
    saveChanges: (skin: Skin, nodes: AreaNode[], difficultyChanged?: boolean) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'nodeChanged', nodeProps: { id: nodes && nodes.length > 0 ? nodes[0].id : 'Not changed', name: nodes && nodes.length > 0 ? nodes[0].headline : 'Not changed' } });
      handleNodeSettingsSave(nodes);
      setOpenNodeSettingsWindow(false);
      if (difficultyChanged) { window.location.reload() }
    },
    saveStateHandler: saveStateHandler
  }
}

function Scene(props: { sceneModel: SceneModel, onEvent: PointerEventHandler, saveStateHandler: SaveStateHandler, score: Score, nodes: AreaNode[], switchGameMode: (newMode?: GameMode) => void, showOverlay: boolean, setShowOverlay: (value: boolean) => void }) {
  const { adventure, theme } = useContext(GameGlobalsContext);

  const [openSkinControlsWindow, setOpenSkinSettingsWindow] = useState<boolean>(false)
  const [openNodeControlsWindow, setOpenNodeSettingsWindow] = useState<boolean>(false)
  const [skinSettingsProps, setSkinSettignsProps] = useState<SettingsProps | undefined>(setupSkinSettingsProps(setOpenSkinSettingsWindow, handleSkinSettingsSave, setOpenNodeSettingsWindow, props.saveStateHandler))
  const [nodeSettingsProps, setNodeSettignsProps] = useState<SettingsProps | undefined>(setupNodeSettignsProps(props.nodes ?? [], setOpenNodeSettingsWindow, handleNodeSettingsSave, props.saveStateHandler))

  const leftHanded = false;

  return (
    <div className={styles.scene} style={{ backgroundImage: `url('./assets/${adventure}-${theme}/scene-${props.sceneModel.background}.png')` }}>
      {props.sceneModel.gameObjects.sort(sortOnZ).map(gameObject => (
        <GameObject key={gameObject.id} gameObject={gameObject} onEvent={props.onEvent} />
      ))}
      {!props.showOverlay && <>
        <Stats className={leftHanded ? styles.leftOverlay : styles.rightOverlay} score={props.score} switchGameMode={props.switchGameMode} saveStateHandler={props.saveStateHandler} />
        <Control className={leftHanded ? styles.rightOverlay : styles.leftOverlay} skinSettingsCallback={() => setOpenSkinSettingsWindow(true)} nodeSettingsCallback={() => setOpenNodeSettingsWindow(true)} setShowOverlay={props.setShowOverlay} />
      </>}
      {openSkinControlsWindow && skinSettingsProps && <SkinSettings {...skinSettingsProps}></SkinSettings>}
      {openNodeControlsWindow && <AreaNodesSettings {...nodeSettingsProps ?? prepareSettings(() => setOpenNodeSettingsWindow(false), () => { setOpenNodeSettingsWindow(false) }, props.saveStateHandler)}></AreaNodesSettings>}
    </div>
  );
}

export function DemoScene(props: { sceneModel: SceneModel, adventure: string, theme: string }) {
  const { sceneModel, adventure, theme } = props

  return (
    <div className={styles.scene} style={{ backgroundImage: `url('./assets/${adventure}-${theme}/scene-${props.sceneModel.background}.png')` }}>
      {sceneModel.gameObjects.sort(sortOnZ).map(gameObject => (
        <DemoGameObject key={gameObject.id} gameObject={gameObject} adventure={adventure} theme={theme} />
      ))}
    </div>
  );
}

export default Scene;
