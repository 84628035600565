import React from 'react';

// TODO: These could be imported lazy, together with "Suspense", in order to decrease bundle size. 
// This change must be done together with pre-loading all actually used task types when student picks curriculum, for offline support. 
import FillGaps from './FillGaps';
import FreeText from './FreeText';
import SortWords from './SortWords';
import PickWords from './PickWords';
import MultipleChoice from './MultipleChoice';

export interface DbTask {
  id: string;
  data: DbTaskDefinition;
}

/** TODO: This should be defined in a React-independent "model" part connected to curriculum definition */
export type TaskType =
  | 'FillGaps'
  | 'FreeText'
  | 'SortWords'
  | 'PickWords'
  | 'MultipleChoice'

interface BaseTaskDefinition {
  type: TaskType;
  meta?: {
    subarea: string;
    gradeYear?: string;
    instructions?: string;
    difficulty?: number;
  }
  nodeRules?: string;
  comment?: string;
}

export interface FillGapsTaskDefinition extends BaseTaskDefinition {
  type: 'FillGaps';
  words: string[];
  gaps: boolean[];
  options: string[];
  comment: string;
}

export interface FreeTextTaskDefinition extends BaseTaskDefinition {
  type: 'FreeText';
  /** A string where pairs of '§§' denote gaps, §§like this§§.
   * The content between pairs is the correct answer for that gap.
   */
  sentence: string;
  caseSensetive: boolean;
}

/** TODO */
export interface SortWordsTaskDefinition extends BaseTaskDefinition {
  type: 'SortWords';
}

/** TODO */
export interface PickWordsTaskDefinition extends BaseTaskDefinition {
  type: 'PickWords';
}

export interface MultipleChoiceTaskDefinition extends BaseTaskDefinition {
  type: 'MultipleChoice';
  question: string;
  alternatives: {
    answer: string;
    isCorrect: boolean;
  }[];
}

export type DbTaskDefinition =
| FillGapsTaskDefinition
| FreeTextTaskDefinition
| SortWordsTaskDefinition
| PickWordsTaskDefinition
| MultipleChoiceTaskDefinition

export type TaskDefinition =
(| FillGapsTaskDefinition
| FreeTextTaskDefinition
| SortWordsTaskDefinition
| PickWordsTaskDefinition
| MultipleChoiceTaskDefinition)
  & {id: string}

export function taskIs<Type extends TaskDefinition['type']>(type: Type) {
  return (task: TaskDefinition): task is Extract<TaskDefinition, { type: Type }> => {
    return task.type === type;
  }
}

export type TaskProps = {
  onCompletion: (task: TaskDefinition, score: number, correctAnswer: string, nodeRules: string, selectedAnswer?: string) => void;
  data: TaskDefinition;
}

export type TaskImplProps = TaskProps;

const taskImpls: {[key in TaskType]: (props: TaskImplProps) => JSX.Element} = {
  'FillGaps': FillGaps,
  'FreeText': FreeText,
  'SortWords': SortWords,
  'PickWords': PickWords,
  'MultipleChoice': MultipleChoice,
};

function Task(props: TaskProps) {
  const TaskImpl = taskImpls[props.data.type];

  return (
    <div className="Task">
      <TaskImpl data={props.data} onCompletion={props.onCompletion} />
    </div>
  );
}

export default Task;
