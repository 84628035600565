import React, { FormEvent, InputHTMLAttributes, useEffect, useState } from 'react';
import { FreeTextTaskDefinition, TaskImplProps } from '.';
import goldenArrowAnimated from './golden_arrow animated.png'
import { WriteSentence } from './TaskUtils';
import styles from './Task.module.css';


type FreeTextSegment = { type: 'text' | 'gap'; value: string; }

type FreeTextAnswer = Record<number, FreeTextSegment['value']>

type FreeTextEngine = {
    segments: () => FreeTextSegment[];
    isAnswerValid: (answer: FreeTextAnswer) => boolean;
    getCorrectAnswer: () => string;
}

const parseFreeTextSentence = (sentence: FreeTextTaskDefinition['sentence']) => (
    sentence.split('§§').map<FreeTextSegment>((slice, index) => ({ type: index % 2 ? 'gap' : 'text', value: slice }))
)

const freeTextEngine = (data: FreeTextTaskDefinition): FreeTextEngine => {
    const segments = parseFreeTextSentence(data.sentence);
    return {
        segments: () => segments,
        isAnswerValid: (answer) => segments.every((segment, index) => segment.type !== 'gap'
            || (!data.caseSensetive ? segment.value.split('&&').some(s => s.trim().toLowerCase() === answer[index].trim().toLowerCase()) : segment.value.split('&&').some(s => s.trim() === answer[index].trim()))),
        getCorrectAnswer: () => segments.flatMap((seg) => seg.type === 'text' ? seg.value.split('||') : `[${seg.value.split('&&').join(' eller ')}]`).join(' ')
    }
}

const emptyFreeTextEngine: FreeTextEngine = {
    segments: () => [],
    isAnswerValid: () => false,
    getCorrectAnswer: () => ''
}

const emptyFreeTextAnswer: FreeTextAnswer = {}

const initialAnswer = (segments: FreeTextSegment[]): FreeTextAnswer => segments
    .reduce((answers: Record<number, FreeTextSegment['value']>, segment, index) => {
        if (segment.type === 'gap') {
            answers[index] = '';
        }
        return answers;
    }, {})

function setEndOfContenteditable(contentEditableElement: Element) {
    var range, selection;
    if (document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
    {
        range = document.createRange();//Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection();//get the selection object (allows you to change selection)
        selection?.removeAllRanges();//remove any selections already made
        selection?.addRange(range);//make the range you have just created the visible selection
    }
}

function FreeText({ data, onCompletion }: TaskImplProps) {
    if (data.type !== 'FreeText') {
        throw new Error(`Unexpected type ${data.type}`)
    }

    const [engine, setEngine] = useState<FreeTextEngine>(emptyFreeTextEngine);
    const [answer, setAnswer] = useState<FreeTextAnswer>(emptyFreeTextAnswer);

    useEffect(() => {
        const engine = freeTextEngine(data);
        setEngine(engine);
        setAnswer(initialAnswer(engine.segments()));
    }, [data]);

    useEffect(() => {
        if (engine) {
            const elements = Array.from(document.getElementsByClassName("gap") as HTMLCollectionOf<HTMLElement>);
            elements.forEach((element, index) => {
                if (element.classList.contains('singlechar')) {
                    element.addEventListener("keydown", function (event) {
                        if (event.key.toLowerCase() === 'backspace'.toLowerCase()) {
                            event.preventDefault();
                            if (element.innerHTML === '' && index > 0) {
                                const prevElement = elements[index - 1]
                                prevElement.focus();
                                setEndOfContenteditable(prevElement);
                            }
                            element.innerHTML = '';
                        } 
                        if(event.key.toLowerCase() === 'enter'.toLowerCase()) {
                            event.preventDefault();
                            if (index + 1 < elements.length) {
                                const nextElement = elements[index + 1]
                                nextElement.focus();
                                setEndOfContenteditable(nextElement);
                            } else {
                                const submit = document.querySelector<HTMLInputElement>('.freetext_submitButton');
                                submit?.click();
                            }
                        }
                    });
                    element.addEventListener("input", function () {
                        const content = element.innerHTML.replaceAll(/<.*>|&nbsp;/g, '');
                        element.innerHTML = content.length > 1 ? content[content.length - 1] : content;
                        setEndOfContenteditable(element);
                        const elementId = element.id.split('_')[1];
                        setAnswerAtIndex(+elementId, element.innerHTML);
                        if (element.innerHTML.length > 0 && index + 1 < elements.length) {
                            const nextElement = elements[index + 1]
                            nextElement.focus();
                            setEndOfContenteditable(nextElement);
                        }
                    }, false);
                }
            });
            if (elements.length > 0) {
                setTimeout(() => elements[0].focus(), 100);
            }
        }
    }, [engine])

    const setAnswerAtIndex = (index: number, value: typeof answer[typeof index]) => {
        setAnswer(a => ({ ...a, [index]: value }));
    }
    const getWidth = (segment: FreeTextSegment) => {
        const list = segment.value.split('&&');
        list.sort((a, b) => a.length - b.length);
        const longestWord = list[list.length - 1];
        if (longestWord.length > 13) { return '200px'; }
        if (longestWord.length > 5) { return '100px'; }
        else { return '50px'; }
    }

    const completedFillGapsEngine = (data: FreeTextTaskDefinition, givenAnwers: FreeTextAnswer): FreeTextEngine => {
        const parsedSentence = parseFreeTextSentence(data.sentence)
        const segments = parsedSentence.map<FreeTextSegment>((segment, index) => ({ type: 'text', value: givenAnwers[index] ?? segment.value }));

        return {
            segments: () => { return segments },
            isAnswerValid: () => { return true },
            getCorrectAnswer: () => { return '' }
        }
    }

    const handleCompletion = (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        onCompletion(
            data,
            engine.isAnswerValid(answer) ? 1 : 0,
            engine.getCorrectAnswer(), data.nodeRules ?? '',
            parseFreeTextSentence(data.sentence).map((seg, index) => seg.type !== 'text' ? answer[index] : '').filter(a => a !== '').join(',')
        );
        setEngine(completedFillGapsEngine(data, answer));
        // setAnswer(emptyFreeTextAnswer);
    }

    return (
        <form onSubmit={event => {
            handleCompletion(event)
        }}>
            <h2 className='header' style={{ fontFamily: 'Architects Daughter, cursive' }}>Fyll i {engine.segments().filter(s => s.type === 'gap').length === 1 ? 'luckan' : 'luckorna'}</h2>
            <div>
                {engine.segments().map((segment, index) => segment.type === 'gap'
                    ? <>{
                        segment.value.length > 1 ? <input key={index} className='gap' style={{ width: getWidth(segment)}} type="text" value={answer[index]} onChange={event => setAnswerAtIndex(index, event.target.value)} tabIndex={0} />
                            : <span key={index} id={'segment_' + index} className={styles.singleCharAnswer + ' singlechar gap'} tabIndex={0} contentEditable ></span>
                    }</>
                    : <WriteSentence key={index} sentenceFragment={segment.value} />
                )}
            </div>
            <br />
            <input className="freetext_submitButton" type="image" src={goldenArrowAnimated} alt="Submit arrow" style={{ width: "50px", maxWidth: '5%' }} autoComplete='off' />

        </form>
    )
}

export default FreeText;
