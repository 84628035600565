
import React, { useCallback, useEffect, useState } from "react"
import { AreaNode } from "./area_nodes"
import styles from './../Settings.module.scss'
import { SaveStateHandler } from "engine/saveStateHandler"
import iconCorrect from './../small-check-mark-icon.svg';
import goldenArrow from './../golden-arrow.png'
import { Skin } from "../SkinChoiceSettings/SkinSettings"
import profileService from "services/profileService";
import { useStudent } from "providers/SignInProvider";

export type ChoiceNode = Omit<AreaNode, 'subareas'> & { isChosen: boolean, choiceSubNodes: ChoiceNode[] }

export type NodeSettingsProps = {
    nodes: ChoiceNode[],
    setChosenNodes: (chosenNodes: ChoiceNode[]) => void,
    style?: React.CSSProperties
}

export type SettingsProps = {
    skins: Skin[]
    nodes: AreaNode[]
    onClose: () => void
    saveChanges: (skin: Skin, nodes: AreaNode[], difficultyChanged?: boolean) => void
    saveStateHandler: SaveStateHandler,
}

const displayNode = (node: ChoiceNode, handleChosenNodes: (id: string) => void, index: number, bold: boolean,  parentIsSelected?: boolean) => {
    return <span key={node.id}>
        <input id={'node_' + node.id} type="checkbox" onChange={() => handleChosenNodes(node.id)} checked={node.isChosen || parentIsSelected} />
        {bold ? <b><label htmlFor={'node_' + node.id}>{node.headline}</label></b>
        : <label htmlFor={'node_' + node.id}>{node.headline}</label>}
        {node.choiceSubNodes && node.choiceSubNodes.length > 0 && <ul className={styles.nodeList}>{
            node.choiceSubNodes.map(cn => cn.headline.trim() !== '' && <li key={cn.id}>{displayNode(cn, handleChosenNodes, index, false, node.isChosen)}</li>)
        }</ul>}
    </span>
}

export const getChosenNodes = (nodes: ChoiceNode[]): ChoiceNode[] => {
    return nodes.flatMap(cn => {
        const tempNodes = getChosenNodes(cn.choiceSubNodes)
        if (cn.isChosen) tempNodes.push(cn)
        return tempNodes;
    })
}

const displayChosenNodes = (chosenNodes: ChoiceNode[]) => {
    const displayNodes = getChosenNodes(chosenNodes)
    return displayNodes.length > 0 ? displayNodes.map(dn => dn.headline).join(',') : undefined
}

const setNodeAsChosen = (nodes: ChoiceNode[], id: string) => {
    nodes.map((tn) => {
        if (id === tn.id) {
            tn.isChosen = !tn.isChosen
        } else {
            tn.isChosen = false;
            // tn.choiceSubNodes = setNodesAsChosen(tn.choiceSubNodes, id);
        }
        tn.choiceSubNodes = setNodeAsChosen(tn.choiceSubNodes, id);
        return tn
    })
    return nodes
}

const getNodeListLength = (nodes: ChoiceNode[]): number => {
    if (!nodes || nodes.length === 0) return 0
    const sum = nodes.map(n => 1 + getNodeListLength(n.choiceSubNodes)).reduce((partialSum, value) => partialSum + value, 0)
    return sum
}



const NodeSettings = (props: NodeSettingsProps) => {
    const { nodes: chosenNodes, setChosenNodes, style } = props

    const handleChosenNodes = (id: string) => {
        let tempNodes = chosenNodes.slice();
        tempNodes = setNodeAsChosen(tempNodes, id)
        setChosenNodes(tempNodes)
    }


    return <div className={styles.settingsCard} style={style} data-testid='node_settings'>
        {chosenNodes.length > 0 ? <>
            <div className={styles.settingsList} data-testid='node_settings_choices_menu'>
                {chosenNodes.map((n, index) => n.headline.trim() !== '' && <div key={index}>
                    {displayNode(n, handleChosenNodes, index, true)}
                </div>)}
            </div>
        </> : <div data-testid='node_settings_not_found'>Inga områden hittade</div>}
    </div>
}


export const convertNodeToChoiceNode = (node: AreaNode, ...chosenIds: string[]): ChoiceNode => {
    return { ...node, isChosen: chosenIds.some(ci => ci === node.id), choiceSubNodes: node.subareas ? node.subareas.map(n => convertNodeToChoiceNode(n, ...chosenIds)) : [] }
}

type Settings = {
    className: string
    saveChanges: (skin: Skin, nodes: AreaNode[], difficultyChange: boolean) => void
    chosenSkin?: Skin
    chosenNodes: ChoiceNode[]
    setChosenNodes: (chosenNodes: ChoiceNode[]) => void
    changeDifficulty: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    difficultyChanged: boolean,
    disableSaveButton: boolean,
}

const VerticleMobile = (props: Settings
    & {
}) => {
    const {
        className,
        saveChanges,
        chosenSkin,
        chosenNodes,
        setChosenNodes,
        changeDifficulty,
        difficultyChanged,
        disableSaveButton,
    } = props

    const student = useStudent();
    const getDefaultValue = () => {
        const difficulty = student?.playerSettings?.difficulty?.min;
        if (difficulty === '0') return 'easy';
        if (difficulty === '400') return 'medium';
        if (difficulty === '600') return 'hard';
        if (difficulty === '-1') return 'all';
        return 'easy'
    }

    return (
        <div className={className} data-testid='settings'>
            <div className={styles.sticky}>
                <span>
                    <div style={{ marginTop: '5%' }}>
                        <b data-testid='node_settings_header'>Övningsområde</b>
                    </div>
                    <span data-testid='node_settings_chosen'>
                        Valt område: {displayChosenNodes(chosenNodes) ?? 'Alla områden'}
                    </span>
                    <br />
                    <span>
                        Vald nivå: <select onChange={changeDifficulty} defaultValue={getDefaultValue()}>
                            <option value="easy">Vit</option>
                            <option value="medium">Blå</option>
                            <option value="hard">Svart</option>
                            <option value="all">Alla nivåer</option>
                        </select>
                    </span>
                </span>
                <button className={styles.arrowButton} disabled={disableSaveButton}
                    onClick={() => saveChanges(chosenSkin ?? { name: 'det stora äventyret', adventureId: 'bare', theme: 'default', image: '', description: 'detta är ett äventyr', isHorizontal: true }, getChosenNodes(chosenNodes), difficultyChanged)}>
                    <img src={goldenArrow} alt="arrow" data-testid='settings_save_button'
                    />
                </button>
            </div>
            <div className={styles.body}>
                {chosenNodes.length > 0 ? <>
                    <div style={{ gridArea: 'top' }}>
                        <input id="allNodes" type="checkbox" onChange={() => (setNodeAsChosen(chosenNodes, '-1'))} checked={displayChosenNodes(chosenNodes) === undefined} />
                        <label htmlFor="allNodes">Alla områden</label>
                    </div>
                    <NodeSettings nodes={chosenNodes} setChosenNodes={setChosenNodes}></NodeSettings>
                </> : <div data-testid='node_settings_not_found'>Inga områden hittade</div>}
            </div>
        </div>
    )
}

const HorizontalMobile = (props: Settings & {
    DivideNodesInHalves: (ChosenNodes: ChoiceNode[]) => ChoiceNode[][],
    setChosenNodesFirstHalf: (chosenNodes: ChoiceNode[]) => void,
    setChosenNodesSecondHalf: (chosenNodes: ChoiceNode[]) => void,
}) => {
    const {
        className,
        saveChanges,
        chosenSkin,
        chosenNodes,
        setChosenNodes,
        DivideNodesInHalves,
        setChosenNodesFirstHalf,
        setChosenNodesSecondHalf,
        changeDifficulty,
        difficultyChanged,
        disableSaveButton,
    } = props

    const student = useStudent();
    const getDefaultValue = () => {
        const difficulty = student?.playerSettings?.difficulty?.min;
        if (difficulty === '0') return 'easy';
        if (difficulty === '400') return 'medium';
        if (difficulty === '600') return 'hard';
        if (difficulty === '-1') return 'all';
        return 'easy'
    }

    return (
        <div className={className} data-testid='settings'>
            <div className={styles.sticky}>
                <span>
                    <b data-testid='node_settings_header' style={{ marginRight: '10%' }}>Övningsområde</b> <br />
                    <span style={{}} data-testid='node_settings_chosen'>
                        Valt område: {displayChosenNodes(chosenNodes) ?? 'Alla områden'}
                    </span>
                    <span>
                        Vald nivå: <select onChange={changeDifficulty} defaultValue={getDefaultValue()}>
                            <option value="easy">Vit</option>
                            <option value="medium">Blå</option>
                            <option value="hard">Svart</option>
                            <option value="all">Alla nivåer</option>
                        </select>
                    </span>
                </span>
                <button className={styles.arrowButton} disabled={disableSaveButton}
                    onClick={() => saveChanges(chosenSkin ?? { name: 'det stora äventyret', adventureId: 'bare', theme: 'default', image: '', description: 'detta är ett äventyr', isHorizontal: true }, getChosenNodes(chosenNodes), difficultyChanged)}>
                    <img src={goldenArrow} alt="arrow" data-testid='settings_save_button'
                    />
                </button>
            </div>
            <div className={styles.body}>
                {chosenNodes.length > 0 ? <>
                    <div className={styles.horizontalGrid}>
                        <div style={{ gridArea: 'top' }}>
                            <input id="allNodes" type="checkbox" onChange={() => (setNodeAsChosen(chosenNodes, '-1'))} checked={displayChosenNodes(chosenNodes) === undefined} />
                            <label htmlFor="allNodes">Alla områden</label>
                        </div>
                        <NodeSettings style={{ gridArea: 'left' }} nodes={DivideNodesInHalves(chosenNodes)[0]} setChosenNodes={setChosenNodesFirstHalf}></NodeSettings>
                        <NodeSettings style={{ gridArea: 'right' }} nodes={DivideNodesInHalves(chosenNodes)[1]} setChosenNodes={setChosenNodesSecondHalf}></NodeSettings>
                    </div>
                </> : <div data-testid='node_settings_not_found'>Inga områden hittade</div>}
            </div>
        </div>
    )
}

const Desktop = (props: Settings & {
    DivideNodesInHalves: (ChosenNodes: ChoiceNode[]) => ChoiceNode[][],
    setChosenNodesFirstHalf: (chosenNodes: ChoiceNode[]) => void,
    setChosenNodesSecondHalf: (chosenNodes: ChoiceNode[]) => void,
}) => {
    const {
        className,
        saveChanges,
        chosenSkin,
        chosenNodes,
        setChosenNodes,
        DivideNodesInHalves,
        setChosenNodesFirstHalf,
        setChosenNodesSecondHalf,
        changeDifficulty,
        difficultyChanged,
        disableSaveButton,
    } = props

    const student = useStudent();
    const getDefaultValue = () => {
        const difficulty = student?.playerSettings?.difficulty?.min;
        if (difficulty === '0') return 'easy';
        if (difficulty === '400') return 'medium';
        if (difficulty === '600') return 'hard';
        if (difficulty === '-1') return 'all';
        return 'easy'
    }

    return (
        <div className={className} data-testid='settings'>
            <div className={styles.sticky}>
                <span>
                    <b data-testid='node_settings_header' style={{ marginRight: '10%' }}>Övningsområde</b> <br />
                    <span style={{}} data-testid='node_settings_chosen'>
                        Valt område: {displayChosenNodes(chosenNodes) ?? 'Alla områden'}
                    </span>
                    <span style={{ marginLeft: "30px" }}>
                        Vald nivå: <select onChange={changeDifficulty} defaultValue={getDefaultValue()}>
                            <option value="easy">Vit</option>
                            <option value="medium">Blå</option>
                            <option value="hard">Svart</option>
                            <option value="all">Alla nivåer</option>
                        </select>
                    </span>
                </span>
                <button className={styles.arrowButton} disabled={disableSaveButton}
                    onClick={() => saveChanges(chosenSkin ?? { name: 'det stora äventyret', adventureId: 'bare', theme: 'default', image: '', description: 'detta är ett äventyr', isHorizontal: true }, getChosenNodes(chosenNodes), difficultyChanged)}>
                    <img src={goldenArrow} alt="arrow" data-testid='settings_save_button'
                    />
                </button>
            </div>
            <div className={styles.body}>
                {chosenNodes.length > 0 ? <>
                    <div className={styles.horizontalGrid}>
                        <div style={{ gridArea: 'top' }}>
                            <input id="allNodes" type="checkbox" onChange={() => (setNodeAsChosen(chosenNodes, '-1'))} checked={displayChosenNodes(chosenNodes) === undefined} />
                            <label htmlFor="allNodes">Alla områden</label>
                        </div>
                        <NodeSettings style={{ gridArea: 'left' }} nodes={DivideNodesInHalves(chosenNodes)[0]} setChosenNodes={setChosenNodesFirstHalf}></NodeSettings>
                        <NodeSettings style={{ gridArea: 'right' }} nodes={DivideNodesInHalves(chosenNodes)[1]} setChosenNodes={setChosenNodesSecondHalf}></NodeSettings>
                    </div>
                </> : <div data-testid='node_settings_not_found'>Inga områden hittade</div>}
            </div>
        </div>
    )
}


export const AreaNodesSettings = (props: SettingsProps): JSX.Element => {
    const {
        skins,
        nodes,
        onClose,
        saveChanges,
        saveStateHandler,
    } = props

    const [chosenNodes, setChosenNodes] = useState<(ChoiceNode)[]>([])
    const [chosenSkin, setChosenSkin] = useState<(Skin | undefined)>(undefined)
    const student = useStudent();
    const [difficultyChanged, setDifficultyChanged] = useState<boolean>(false);
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

    const changeDifficulty = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDisableSaveButton(true);
        const difficulty = event.target.value
        let difficultyMin = -1;
        let difficultyMax = -1;
        if (difficulty === 'easy') {
            difficultyMin = 0;
            difficultyMax = 500;
            setDifficultyChanged(true);
        } else if (difficulty === 'medium') {
            difficultyMin = 400;
            difficultyMax = 700;
            setDifficultyChanged(true);
        } else if (difficulty === 'hard') {
            difficultyMin = 600;
            difficultyMax = 1000;
            setDifficultyChanged(true);
        } else if (difficulty === 'all') {
            difficultyMin = -1;
            difficultyMax = -1;
            setDifficultyChanged(true);
        }
        profileService.setUserDifficulty({ min: difficultyMin.toString(), max: difficultyMax.toString() })
        .then(() => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ event: 'changeDifficulty', difficultyProps: {difficulty} });
        }).finally(() => {
            setDisableSaveButton(false);
        });
    }

    const DivideNodesInHalves = useCallback((nodes: ChoiceNode[]) => {
        nodes.forEach(n => n.choiceSubNodes.forEach(sn => sn.choiceSubNodes = [])) // only show nodes and the direct subnodes
        const sortedNodes = nodes.sort((a, b) => getNodeListLength([b]) - getNodeListLength([a]))
        const nodeLengths = sortedNodes.map(n => getNodeListLength([n]))
        const desiredLength = Math.ceil(nodeLengths.reduce((sum, a) => sum + a, 0) / 2)

        // let dividingIndex: number = nodes.length
        let currentLength: number = 0
        const leftSideIndexes: number[] = []
        nodeLengths.forEach((length, index) => {
            if (index === 0) {
                leftSideIndexes.push(index)
                currentLength += length
            }
            else if (currentLength + length <= desiredLength) {
                leftSideIndexes.push(index)
                currentLength += length
            }
        });

        const first = sortedNodes.filter((n, index) => leftSideIndexes.some(li => li === index))
        const second = sortedNodes.filter((n, index) => leftSideIndexes.every(li => li !== index))
        return [first, second]
    }, [])

    const setChosenNodesFirstHalf = (updatedHalf: ChoiceNode[]) => {
        setChosenNodes(updatedHalf.concat(setNodeAsChosen(DivideNodesInHalves(chosenNodes)[1], '-1')))
    }

    const setChosenNodesSecondHalf = (updatedHalf: ChoiceNode[]) => {
        setChosenNodes(setNodeAsChosen(DivideNodesInHalves(chosenNodes)[0], '-1').concat(updatedHalf))
    }

    useEffect(() => {
        if (!chosenSkin) {
            saveStateHandler.loadScene().then(scene => {
                if (scene) {
                    const chosenSkin = skins.find(s => s.adventureId === scene.adventureId)
                    setChosenSkin(chosenSkin)
                }
            })
        }
        if (nodes.length !== 0 && chosenNodes.length === 0) {
            const params = new URLSearchParams(window.location.search);
            const paramValue = params.get("node");

            if (paramValue) {
                setChosenNodes(nodes.map((n) => {
                    return convertNodeToChoiceNode(n, paramValue)
                }))
            } else setChosenNodes(nodes.map((n) => convertNodeToChoiceNode(n)))
        }
    }, [nodes, chosenNodes, saveStateHandler, skins, chosenSkin])

    const settings: Omit<Settings, 'className'> = {
        saveChanges: saveChanges,
        chosenSkin: chosenSkin,
        chosenNodes: chosenNodes,
        setChosenNodes,
        changeDifficulty,
        difficultyChanged,
        disableSaveButton,
    }

    return (<>
        <Desktop className={styles.settings + ' ' + styles.desktop}
            {...settings}
            DivideNodesInHalves={DivideNodesInHalves}
            setChosenNodesFirstHalf={setChosenNodesFirstHalf}
            setChosenNodesSecondHalf={setChosenNodesSecondHalf}
        />
        <HorizontalMobile className={styles.settings + ' ' + styles.horizontalMobile}
            {...settings}
            DivideNodesInHalves={DivideNodesInHalves}
            setChosenNodesFirstHalf={setChosenNodesFirstHalf}
            setChosenNodesSecondHalf={setChosenNodesSecondHalf}
        />
        <VerticleMobile className={styles.settings + ' ' + styles.verticleMobile}
            {...settings}
        />
        <div style={{ position: 'fixed', inset: 0, backgroundColor: '#000a' }}></div>
    </>)
}

export default AreaNodesSettings

