import { TaskDefinition } from "@task";
import { ChallengeProps } from "components/challenge";
import ChallengeDefinition, { DuelDefinition, PuzzleDefinition } from "./models/challenge";
import gameService from "../services/gameService";
import GameDefinition, { GameObjectPosition } from "./models/gameDefinition";
import createScene, { SceneEngine, SceneModel } from "./scenes";
import { initSaveStateHandler, SaveStateHandler } from "./saveStateHandler";
import { AreaNode } from "components/settings/AreaNodeSettings/area_nodes";
import { useMemo } from "react";
import { Animation, setupMove, updateAnimations } from "engine/animation";
import { Movement } from "./models/sceneMechanicsDefinition";
import { GameObjectModel, GameObjectState } from "./models/gameObject";
import { StudentSessionContext } from "services/sessionService";

export type TimedGameMode = {
    type: 'Timed'
    isTimed: true
    timeLimit: number
    startTime: number
    // onCompletion: () => void
}

export type NotTimedMode = {
    type: 'NotTimed'
    isTimed: false
}

export type OneHundredGameMode = {
    type: 'OneHundred'
    isTimed: false
}

export type GameMode = (TimedGameMode | NotTimedMode | OneHundredGameMode)
function isMode<Type extends GameMode['type']>(type: Type) {
    return (mode: GameMode): mode is Extract<GameMode, { type: Type }> => {
        return mode.type === type
    }
}

export interface GameGlobals {
    adventure: string;
    theme: string;
    timer: number;
    gameMode: GameMode
    areaNodes: AreaNode[]
}

export interface Stats {
    score: number;
}

export type GameObjectStateValue = 'unpresent' | 'waiting' | 'active' | 'done';

export type GameAction = 'pick' | 'next' | 'toggleMessage' | 'switchScene' | 'startChallenge';

export interface QuestCompletedEventModel {
    type: 'questCompleted'
}

export interface CelebrationEventModel {
    type: 'celebration'
    object: string
}

export type GameEventModel = QuestCompletedEventModel | CelebrationEventModel

export interface PointerEventModel {
    type: 'pointerDown' | 'pointerUp' | 'pointerMove' | 'click',
    position: { x: number, y: number } | null,
    target: GameObjectModel | null
}

export interface KeyEventModel {
    type: 'down' | 'up' | 'press',
    key: 'upArrow' | 'downArrow' | 'rightArrow' | 'downArrow' | 'ESC'
}

export interface GameState {
    scene: SceneModel;
    stats: {};
    challenge: ChallengeDefinition | null;
    outsideObjects: GameObjectModel[]
}

export type KeyEventHander = (event: KeyEventModel) => void;
export type PointerEventHandler = (event: PointerEventModel) => void;
export type GameEventHandler = (event: GameEventModel) => void;

export interface GameActionHandlers {
    switchScene: (nextScene: string, playerPositionForNextScene: GameObjectPosition) => void;
    startChallenge: (id: string, definition: ChallengeDefinition) => void;
    retreatFromChallenge: ChallengeProps['onRetreat'];
    completeChallenge: ChallengeProps['onSuccess'];
    completeQuest: () => void;
    completeEvent: (gameEvent: GameEventModel) => void;
    switchGameMode: (newMode?: GameMode) => void
}

export const EmptyGameActionHandlers: GameActionHandlers = {
    switchScene: () => { },
    startChallenge: () => { },
    retreatFromChallenge: () => { },
    completeChallenge: () => { },
    completeQuest: () => { },
    completeEvent: () => { },
    switchGameMode: () => { },
}

export interface GameEngine {
    actions: GameActionHandlers;
    getGameGlobals: () => GameGlobals;
    getTask: () => TaskDefinition;
    keyEvent: KeyEventHander;
    pointerEvent: PointerEventHandler;
    taskResult: ({ }) => void;
    tick: (elapsedTime: number) => GameState;
    saveStateHandler: SaveStateHandler;
}

export const initGame: (studentContext: StudentSessionContext | null) => Promise<GameEngine | null> = async (studentContext: StudentSessionContext | null) => {
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get("node") ?? undefined;
    let tasks = await gameService.getTasks(paramValue, undefined, studentContext?.playerSettings?.difficulty);
    const saveStateHandler = initSaveStateHandler()

    let challengerId: string | null = null;
    let challenge: ChallengeDefinition | null = null;

    let elapsed: number | undefined
    let mode: GameMode | undefined
    let nodes = await gameService.getAreaNodes()

    const animations: Animation[] = []
    let outsideObjects: GameObjectState[] = []

    const actions: GameActionHandlers = {
        switchScene: async (idForNextScene, playerPositionForNextScene) => {
            const switchTo = scenes[idForNextScene];
            if (switchTo) {
                currentScene = switchTo;
                await saveStateHandler.saveScene({
                    adventureId: gameDefinition.adventure,
                    sceneId: idForNextScene,
                    theme: gameDefinition.theme,
                }, true)
            }
        },
        startChallenge: (id, definition) => {
            challengerId = id;
            challenge = definition;
        },
        retreatFromChallenge: () => {
            challengerId = null;
            challenge = null;
        },
        completeChallenge: (bonusPoints?: number) => {
            saveStateHandler.uploadAnswers()
            if (challengerId === null) return;
            currentScene.completeChallenge(challengerId);
            saveStateHandler.saveChallenge({ challengerId })
            challengerId = null;
            challenge = null;
            const studentId = studentContext?.id?.toString()
            if (bonusPoints && studentId) {
                gameService.saveBonusScore(bonusPoints, studentId).then(response => {
                    console.log('bonus saved')
                })
            }


            if (sceneList.every(s => s.challengesRemaining() === 0)) {
                for (const scene of sceneList) {
                    scene.gameEvent({ type: 'questCompleted' });
                }
            }
        },
        completeEvent: (gameEvent) => {
            // currentScene.gameEvent(gameEvent)
            switch (gameEvent.type) {
                case 'celebration':
                    let tempOutsideObject = []
                    for (let i = 0; i < 5; i++) {
                        const start = -15 + Math.random() * 30
                        const object: GameObjectState = {
                            id: 'celebration' + i,
                            avatar: gameEvent.object,
                            clickable: false,
                            height: 10,
                            width: 10,
                            xPos: 50 + start,
                            yPos: 100,
                            zPos: 12,
                            opacity: 1,
                            message: null,
                            state: 'active',
                            click: []
                        }

                        tempOutsideObject.push(object)
                    }
                    const elapsedTime = elapsed ?? 0
                    tempOutsideObject.forEach(os => {
                        const end = -50 + Math.random() * 100
                        const movement: Movement = { to: { x: 50 + end, y: -10, z: 12 }, easing: 'linear', speed: 0.02 }
                        animations.push(setupMove(os, movement, elapsedTime))
                    })
                    outsideObjects = tempOutsideObject
                    break;

                default:
                    break;
            }

        },
        completeQuest: () => {
            // TODO: load a new quest
            setupQuest();
        },
        switchGameMode: (newMode?: GameMode) => {
            mode = newMode
        },

    };

    const getGameGlobals: GameEngine['getGameGlobals'] = () => ({
        adventure: gameDefinition.adventure,
        theme: gameDefinition.theme,
        timer: elapsed ?? 0,
        gameMode: mode ?? { type: 'NotTimed', isTimed: false },
        areaNodes: nodes,
    });

    let taskIndex = 0;
    const getTask: GameEngine['getTask'] = () => {
        // TODO: associate challengerId with a specific task type and return tasks of that type
        const task = tasks[taskIndex];
        if (tasks.length === taskIndex + 10) {
            gameService.getTasks(paramValue, undefined, studentContext?.playerSettings?.difficulty).then(t => {
                tasks = tasks.concat(t);
            });
        }
        taskIndex = (taskIndex + 1) % tasks.length;
        return task;
    };

    // IMPORTANT:
    // make sure to always set the following variables in setupQuest
    // and make sure to call setupQuest before using them!
    let scenes !: Record<string, SceneEngine>;
    let sceneList !: SceneEngine[];
    let currentScene !: SceneEngine;
    let gameDefinition !: GameDefinition

    const setNextQuestBackground = (() => {
        let indices: Record<string, number> = {};
        return (scenes: GameDefinition['scenes']) => {
            for (const scene of scenes) {
                if (scene.id in indices) {
                    scene.background = scene.backgrounds[indices[scene.id]];
                    indices[scene.id] = (indices[scene.id] + 1) % scene.backgrounds.length;
                } else {
                    const originalBackgroundIndex = scene.backgrounds.findIndex(bg => bg === scene.background);
                    // if the original background was not in the backgrounds array, adding +1 yields 0
                    indices[scene.id] = (originalBackgroundIndex + 1) % scene.backgrounds.length;
                }
            }
            const questEntry = scenes[0].objects.find(obj => obj.id === 'assignmentRoomToQuestTransition');
            if (questEntry) {
                questEntry.avatar = `transition-${scenes[1].background}-sv-sf`;
            }
        }
    })();

    const setupQuest = async () => {
        const savedScene = await saveStateHandler.loadScene()
        if (!gameDefinition || gameDefinition.adventure !== savedScene?.adventureId) {
            gameDefinition = await gameService.getGameDefinition(savedScene?.adventureId ?? 'battleground', savedScene?.theme ?? 'fantasy');
        } else {
            setNextQuestBackground(gameDefinition.scenes);
        }

        let currentSceneIndex = 0
        let challengerId: string | undefined = savedScene?.challengeData?.challengerId
        scenes = gameDefinition.scenes.reduce<Record<string, SceneEngine>>((map, scene, index) => {
            if (savedScene && savedScene.sceneId === scene.id) { currentSceneIndex = index }
            map[scene.id] = createScene(scene, gameDefinition.player, actions, saveStateHandler, challengerId);
            return map;
        }, {});
        sceneList = Object.values(scenes);
        currentScene = sceneList[currentSceneIndex];
        if (challengerId) {
            currentScene.completeChallenge(challengerId);

        }
    };

    await setupQuest();

    const tick = (elapsedTime: number): GameState => {
        const scene = currentScene.tick(elapsedTime);
        updateAnimations(animations, elapsedTime)

        elapsed = elapsedTime

        return {
            scene,
            stats: {},
            challenge,
            outsideObjects,
        };
    };

    const keyEvent: KeyEventHander = ({ }) => { };

    const pointerEvent: PointerEventHandler = event => {
        currentScene.pointerEvent(event);
    };

    const taskResult = ({ }) => { };

    return {
        actions,
        getGameGlobals,
        getTask,
        keyEvent,
        pointerEvent,
        taskResult,
        tick,
        saveStateHandler
    };
};
