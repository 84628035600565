import React, { useCallback, useEffect, useMemo, useState } from 'react';
import sessionService, { StudentSessionContext } from 'services/sessionService';
import useAsyncValue from 'hooks/useAsyncValue';

type SignedInAuthContext = {
    isLoggedIn: true;
    logout: () => Promise<boolean>;
};
type SignedOutAuthContext = {
    isLoggedIn: false;
};
export type AuthContext = SignedInAuthContext | SignedOutAuthContext;

const ContextForAuth = React.createContext<AuthContext | null>(null);
const ContextForStudent = React.createContext<StudentSessionContext | null>(null);

const signedInAuthContext = (on: { logout: () => void }): SignedInAuthContext => {
    let loggedIn = true
    return {
        isLoggedIn: loggedIn,
        logout: () => sessionService.logout().then(on.logout).then(() => { loggedIn = false; return true }, () => false),
    }
};
const signedOutAuthContext = (): SignedOutAuthContext => {
    return {
        isLoggedIn: false,
    };
};

export const useAuth = () => React.useContext(ContextForAuth);
export const useStudent = () => React.useContext(ContextForStudent);

const useStudentSignIn = (onSignout: () => void): { studentContext: StudentSessionContext | null, authContext: AuthContext | null } => {
    const asyncStudentContext = useAsyncValue((abortSignal) => sessionService.getStudentSession({ abortSignal }));

    const studentContext = asyncStudentContext.state.type === 'value' ? asyncStudentContext.state.value : null;

    const authContext = useMemo(() => {
        switch (asyncStudentContext.state.type) {
            case 'value': return signedInAuthContext({
                logout: () => { asyncStudentContext.setState(null); onSignout(); },
            });
            case 'error': return signedOutAuthContext();
        }

        return null;
    }, [asyncStudentContext]);

    return { studentContext, authContext };
}

export const SignInProvider = (props: { children: React.ReactNode }) => {
    const [authCon, setAuthCon] = useState<AuthContext | null>(null)
    const onSignout = () => setAuthCon(signedOutAuthContext);
    const { authContext, studentContext } = useStudentSignIn(onSignout);
    
    useEffect(() => {
        if(!authCon)
            setAuthCon(authContext)
    }, [setAuthCon, authContext, authCon])
    return (
        <ContextForAuth.Provider value={authCon}>
            <ContextForStudent.Provider value={studentContext}>
                {props.children}
            </ContextForStudent.Provider>
        </ContextForAuth.Provider>
    );
};
