import Challenge from 'components/challenge'
import Scene from 'components/scene'
import { Score } from 'components/sideMenus/Stats'
import { AreaNode } from 'components/settings/AreaNodeSettings/area_nodes'
import { GameEngine, GameState } from 'engine/game'
import React from 'react'


const AdventureTime = (props: {
    engine: GameEngine,
    state: GameState,
    score: Score,
    setScore: (value: number) => void,
    nodes: AreaNode[],
    showOverlay: boolean,
    setShowOverlay: (value: boolean) => void,
}) => {
    const {
        engine,
        state,
        score,
        setScore,
        nodes,
        showOverlay,
        setShowOverlay,
    } = props

    const logChallenge = () => {
        return true
    }

    return <>
        <Scene sceneModel={state.scene} onEvent={engine.pointerEvent} saveStateHandler={engine.saveStateHandler} score={score} nodes={nodes ?? []} switchGameMode={engine.actions.switchGameMode} showOverlay={showOverlay} setShowOverlay={setShowOverlay}></Scene>
        {logChallenge() && state.challenge && <Challenge
            {...state.challenge}
            score={score}
            setTotalScore={setScore}
            getTask={engine.getTask}
            onSuccess={engine.actions.completeChallenge}
            onRetreat={engine.actions.retreatFromChallenge}
            saveAnswer={engine.saveStateHandler?.saveAnswer}
        />}
    </>
}

export default AdventureTime;