import { DbTask, TaskDefinition } from '@task';
import { AreaNode } from 'components/settings/AreaNodeSettings/area_nodes';
import { ChallengeSaveState, TaskActivity } from 'engine/saveStateHandler';
import GameDefinition from "../engine/models/gameDefinition";
import { SceneState } from "../engine/saveStateHandler"

async function getGameDefinition(adventure: string, theme: string): Promise<GameDefinition> {
    // TODO: move "live" adventures to a directory without "mocks" in its name
    if (adventure === 'bare') {
        const adventure = await import('../stories/mocks/bareGameDefinition').then((v) => v.bareGameDefinition);
        return { ...adventure, theme };
    } else if (adventure === 'battleground') {
        const adventure = await import('../stories/mocks/battlegroundGameDefinition').then((v) => v.battlegroundGameDefinition);
        return { ...adventure, theme };
    } else if (adventure === 'poc') {
        const adventure = await import('../stories/mocks/pocGameDefinition').then((v) => v.pocGameDefinition);
        return { ...adventure, theme };
    } else if (adventure === 'eternal') {
        const adventure = await import('../stories/mocks/andreasGameDefinition').then((v) => v.andreasGameDefinition);
        return { ...adventure, theme }
    }

    throw new Error(`adventure not found: ${adventure}-${theme}`);
}

async function getTasks(nodeId: string | undefined = undefined, nrOfQuestions: number | undefined = undefined, difficulty?: { min: string, max: string }): Promise<TaskDefinition[]> {
    let urlString = `${process.env.REACT_APP_API_BASE}/tasks`;
    if (nodeId || nrOfQuestions || difficulty) {
        urlString += '?'
        if (nodeId) urlString += `nodeId=${nodeId}`
        if (nodeId && nrOfQuestions) urlString += '&';
        if (nrOfQuestions) urlString += `nr=${nrOfQuestions}`
        if (difficulty) {
            if (!urlString.endsWith('?')) urlString += '&';
            urlString += `difficultyMin=${difficulty.min}&difficultyMax=${difficulty.max}`

        }
    }
    const response = await fetch(urlString, {
        credentials: 'include',
    });
    return response?.json()?.then((tasks: DbTask[]) => tasks.map(task => { return { ...task.data, id: task.id }; })) ?? [];
    // const test2 = await response.json().then((tasks: DbTask[]) => tasks.map(task => ({...task.data})));
    // return response.json();
}

async function saveStateScene(sceneState: SceneState, replaceData: boolean) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/save/scene`,
        {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({ sceneState, replaceData })
        })
    return response.json()
}

async function getStateScene(): Promise<any | null> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/save/scene`,
        {
            credentials: 'include',
        })
    return response.status === 200 ? response.json() : null
}

async function saveChallengeState(challengeState: ChallengeSaveState) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/save/challenge`,
        {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(challengeState)
        })
    return response.json()
}

async function getAreaNodes(): Promise<AreaNode[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/nodes/top`, {
        credentials: 'include',
    });
    return response.json();
}

async function saveActivities(activities: TaskActivity[]): Promise<Response> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/activity`, {
        method: 'Post',
        credentials: 'include',
        body: JSON.stringify(activities),
    })
    return response;
}

async function getStudentScore(studentId: string): Promise<{ studentId: string, score: number, topStreak: number, currentStreak: number, bonusPoints: number, loginStreak: number }> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/student/${studentId}/score`, {
        credentials: 'include'
    })

    return response.json()
}

async function getTimedModeScore(studentId: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/save/modes/timed/student/${studentId}`, {
        method: 'get',
        credentials: 'include',
    })
    return response.json();
}

async function saveTimedModeScore(score: number, timeLimit: number, studentId: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/save/modes/timed/student/${studentId}`, {
        method: 'Post',
        credentials: 'include',
        body: JSON.stringify({ mode: 'timed', score, timeLimit })
    })
    return response.json();
}

async function getOneHundredModeScore(studentId: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/save/modes/onehundred/student/${studentId}`, {
        method: 'get',
        credentials: 'include',
    })
    return response.json();
}

async function saveOneHundredModeScore(score: number, questionLimit: number, studentId: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/save/modes/onehundred/student/${studentId}`, {
        method: 'Post',
        credentials: 'include',
        body: JSON.stringify({ mode: 'onehundred', score, questionLimit })
    })
    return response.json();
}

async function getScores(studentId: string): Promise<{ score: number, topStreak: number, currentStreak: number }> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/save/modes/scores/student/${studentId}`, {
        method: 'get',
        credentials: 'include',
    })
    return response.json();
}

async function getStreakScore(studentId: string, type: 'streak' | 'current_streak') {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/save/modes/${type}/student/${studentId}`, {
        method: 'get',
        credentials: 'include',
    })
    return response.json();
}

async function saveStreakScore(score: number, studentId: string, type: 'streak' | 'current_streak', onlyBest: boolean) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/save/modes/${type}/student/${studentId}?onlyBest=${onlyBest}`, {
        method: 'Post',
        credentials: 'include',
        body: JSON.stringify({ mode: type, score })
    })
    return response.json();
}

async function saveBonusScore(score: number, studentId: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/save/modes/bonus/student/${studentId}?onlyBest=false&additive=true`, {
        method: 'Post',
        credentials: 'include',
        body: JSON.stringify({ mode: 'bonus', score })
    })
    return response.json();
}

async function reportBug(taskId: string, message: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/feedback/tasks/${taskId}/bug`, {
        method: 'Post',
        credentials: 'include',
        body: JSON.stringify({ message })
    })
    return response.json();
}

export const gameService = {
    getTasks,
    getGameDefinition,
    saveStateScene,
    getStateScene,
    saveChallengeState,
    getAreaNodes,
    saveActivities,
    getScores,
    getStudentScore,
    getTimedModeScore,
    saveTimedModeScore,
    getOneHundredModeScore,
    saveOneHundredModeScore,
    getStreakScore,
    saveStreakScore,
    saveBonusScore,
    reportBug,
};

export default gameService; 